import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaStar, FaStarHalf } from "react-icons/fa";
import SummaryApi from "../../common";
import ShopProductDetails from "./ShopProductDetails";
import addToCart from "../cart/AddToCart";
import { useDispatch } from "react-redux";
import { setCartCount } from "../../store/cartSlice";

const ProductDetails = () => {
  const [data, setData] = useState({
    productName: "",
    // brandName: "",
    category: "",
    productImage: [],
    description: "",
    price: "",
    sellingPrice: "",
  });

  const userDetails = JSON.parse(localStorage.getItem("session"));

  const [loading, setLoading] = useState(true);
  // const productImageListLoading = new Array(4).fill(null);
  const [activeImage, setActiveImage] = useState("");
  const [zoomImageCoordinate, setZoomImageCoordinate] = useState({
    x: 0,
    y: 0,
  });
  const [zoomImage, setZoomImage] = useState(false);

  const params = useParams();

  const fetchProductDetails = async () => {
    setLoading(true);
    const response = await fetch(SummaryApi.productdetails.url, {
      method: SummaryApi.productdetails.method,
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        productId: params?.id,
      }),
    });
    setLoading(false);

    const dataResponse = await response.json();
    setData(dataResponse?.data);
    setActiveImage(dataResponse?.data?.productImage[0]);
  };

  useEffect(() => {
    fetchProductDetails();
  }, [params]);

  // const handleMouseEnterProduct = (imageURL) => {
  //   setActiveImage(imageURL);
  // };

  const handleZoomImage = useCallback(
    (e) => {
      setZoomImage(true);
      const { left, top, width, height } = e.target.getBoundingClientRect();
      const x = (e.clientX - left) / width;
      const y = (e.clientY - top) / height;

      setZoomImageCoordinate({ x, y });
    },
    [zoomImageCoordinate]
  );

  const handleLeaveImageZoom = () => {
    setZoomImage(false);
  };

  //   const { fetchUserAddToCart } = useContext(Context);
  const dispatch = useDispatch();

  const fetchUserAddToCart = async () => {
    const dataResponse = await fetch(SummaryApi.addToCartProductCount.url, {
      method: SummaryApi.addToCartProductCount.method,
      credentials: "include",
    });

    const dataApi = await dataResponse.json();

    // console.log("data-user-api ", dataApi);
    // setCartProductCount(dataApi?.data?.count);
    dispatch(setCartCount(dataApi?.data?.count));
  };

  const navigate = useNavigate();

  const handleAddToCart = async (e, id, uId) => {
    await addToCart(e, id, uId);
    fetchUserAddToCart();
  };

  const handleBuyProduct = async (e, id, uId) => {
    await addToCart(e, id, uId);
    fetchUserAddToCart();
    navigate("/cart");
  };

  return (
    <div className="container mx-auto p-4">
      <div className="min-h-[200px] flex flex-col lg:flex-row gap-4">
        {/* product Image */}
        <div className="h-96 flex flex-col lg:flex-row-reverse gap-4">
          <div className="h-[300px] w-[300px] lg:h-96 lg:w-96 bg-white relative p-2">
            <img
              src={activeImage}
              alt="img"
              className="h-full w-full object-scale-down mix-blend-multiply"
              onMouseMove={handleZoomImage}
              onMouseLeave={handleLeaveImageZoom}
            />
            {/* product zoom */}
            {zoomImage && (
              <div className="hidden lg:block absolute min-w-[500px] min-h-[400px] bg-slate-200 p-1 -right-[510px] top-0 overflow-hidden">
                <div
                  className="w-full h-full mix-blend-multiply min-h-[400px] min-w-[500px] scale-150"
                  style={{
                    backgroundImage: `url(${activeImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: `${zoomImageCoordinate.x * 100}% ${
                      zoomImageCoordinate.y * 100
                    }%`,
                  }}
                ></div>
              </div>
            )}
          </div>
          {/* <div className="h-full">
            {loading ? (
              <div className="flex gap-2 lg:flex-col overflow-scroll scrollbar-none h-full">
                {productImageListLoading.map((el, index) => {
                  return (
                    <div
                      className="h-20 w-20 bg-slate-200 rounded animate-pulse"
                      key={"loadingImage" + index}
                    ></div>
                  );
                })}
              </div>
            ) : (
              <div className="flex gap-2 lg:flex-col overflow-scroll scrollbar-none h-full">
                {data?.productImage?.map((imageUrl, index) => {
                  return (
                    <div
                      className="h-20 w-20 bg-slate-200 rounded p-1"
                      key={imageUrl}
                    >
                      <img
                        src={imageUrl}
                        alt={"product-img"}
                        className="w-full h-full object-scale-down mix-blend-multiply cursor-pointer"
                        onMouseEnter={() => handleMouseEnterProduct(imageUrl)}
                        onClick={() => handleMouseEnterProduct(imageUrl)}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div> */}
        </div>
        {/* product details */}
        {loading ? (
          <div className="grid gap-1 w-full">
            <p className="bg-slate-200 animate-pulse h-6 lg:h-8 w-full rounded-full inline-block"></p>
            <h2 className="text-2xl lg:text-4xl font-medium bg-slate-200 animate-pulse h-6 lg:h-8 w-full"></h2>
            <p className="capitalize text-slate-400 bg-slate-200 min-w-[100px] animate-pulse h-6 lg:h-8 w-full"></p>

            <div className="text-red-600 flex items-center gap-1 bg-slate-200 animate-pulse h-6 lg:h-8 w-full"></div>

            <div className="flex items-center gap-2 text-2xl lg:text-3xl font-medium my-1 animate-pulse h-6 lg:h-8 w-full">
              <p className="text-red-600 bg-slate-200 w-full h-6 lg:h-8"></p>
              <p className="text-slate-400 line-through bg-slate-200 w-full h-6 lg:h-8"></p>
            </div>

            <div className="flex items-center gap-3 my-2 w-full">
              <button className="bg-slate-200 h-6 lg:h-8 animate-pulse rounded w-full"></button>
              <button className="bg-slate-200 h-6 lg:h-8 animate-pulse rounded w-full"></button>
            </div>

            <div className="w-full">
              <p className="text-slate-600 font-medium my-1 h-6 lg:h-8 bg-slate-200 animate-pulse rounded"></p>
              <p className="bg-slate-200 h-10 lg:h-12 animate-pulse rounded"></p>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-1">
            {/* <p className="bg-red-200 text-red-600 px-2 rounded-full inline-block w-fit">
              {data?.brandName}
            </p> */}
            <h2 className="text-2xl lg:text-4xl font-medium">
              {data?.productName}
            </h2>
            <p className="capitalize text-slate-400">{data?.category}</p>

            <div className="text-red-600 flex items-center gap-1">
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStarHalf />
            </div>

            <div className="flex items-center gap-2 text-2xl lg:text-3xl font-medium my-1">
              <p className="text-red-600">{data?.sellingPrice + " GBP"}</p>
              <p className="text-slate-400 line-through">
                {data?.price + " GBP"}
              </p>
            </div>

            <div className="flex items-center gap-3 my-2">
              <button
                className="border-2 border-red-600 rounded px-3 py-1 min-w-[120px] text-red-600 font-medium hover:bg-red-600 hover:text-white"
                onClick={(e) => {
                  handleBuyProduct(e, data?._id, userDetails?._id);
                }}
              >
                Buy
              </button>
              <button
                className="border-2 border-red-600 rounded px-3 py-1 min-w-[120px] bg-red-600 text-white font-medium hover:bg-white hover:text-red-600"
                onClick={(e) => handleAddToCart(e, data?._id, userDetails?._id)}
              >
                Add To Cart
              </button>
            </div>

            <div>
              <p className="text-slate-600 font-medium my-1">Description :</p>
              <p>{data?.description}</p>
            </div>
          </div>
        )}
      </div>

      {/* {data?.category && (
        <CategoryWiseProductDisplay
          category={data?.category}
          heading={"Recommended Products"}
        />
      )} */}
      {data?.category && (
        <ShopProductDetails
          category={data?.category}
          heading={"Recommended Products"}
        />
        // <ShopProductDetails category={"Combos"} heading={"Recommended Combos"} />
      )}
    </div>
  );
};

export default ProductDetails;
