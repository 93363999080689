import React from "react";

const PdfCourse2 = () => {
  return (
    <div>
      <iframe
        src="CONTENTSMODULESS.pdf"
        width="100%"
        style={{ height: "83vh" }}
        title="pdfFile"
      />
    </div>
  );
};

export default PdfCourse2;
